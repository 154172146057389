import { Container } from "@chakra-ui/react";
import React from "react";

const Active = () => {
  return (
    <Container w="80%" h="400px" bg="white" b="5px">
      <h1>Active</h1>
    </Container>
  );
};

export default Active;
