export const BUS_SEARCH_REQUEST = "BUS_SEARCH_REQUEST";
export const BUS_SEARCH_SUCCESS = "BUS_SEARCH_SUCCESS";
export const BUS_SEARCH_FAILURE = "BUS_SEARCH_FAILURE";
export const BUS_BLOCK_REQUEST = "BUS_BLOCK_REQUEST";
export const BUS_BLOCK_SUCCESS = "BUS_BLOCK_SUCCESS";
export const BUS_BLOCK_FAILURE = "BUS_BLOCK_FAILURE";
export const BUS_BOOK_REQUEST="BUS_BOOK_REQUEST";
export const BUS_BOOK_SUCCESS="BUS_BOOK_SUCCESS";
export const BUS_BOOK_FAILURE="BUS_BOOK_FAILURE";
export const BUS_DETAILS_REQUEST="BUS_DETAILS_REQUEST";
export const BUS_DETAILS_SUCCESS="BUS_DETAILS_SUCCESS";
export const CLEAR_BUS_SEARCH_REDUCER = "CLEAR_BUS_SEARCH_REDUCER";
export const BUS_BOOK_BACK = "BUS_BOOK_BACK";
