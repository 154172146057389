export default {
  primary: '#125df0',
  blue: '#2893F1',
  blue90: '#2893F199',
  sky: '#C7E5F0',
  bluedark:'#21325D',
  orange: '#FF951A',
  primary1: '#00a896',
  secondary: '#9b7ba2',
  homeBackground: '#eff3ff',
  backgroundLight: '#EFF5FF',
  homeBackground140: '#bcf8ec40',
  homeBackground1: '#bcf8ec',
  button: '#125df0',
  button1: '#2a9d8f',
  chip: '#badcf9',
  danger: '#cd2027',
  success: '#4cc9f0',
  darkgrey: '#5A5A5A',
  darkgrey99: '#5A5A5A99',
  grey: '#808080',
  lightgrey: '#dfe4ea',
  homeicon: '#4544ac',
  green: '#2a9d8f',
  white: '#ffffff',
  white90: '#ffffff',
  black: '#303030',
  off_white: '#f8f8f8',
  brown: '#6F4E37',
  yellow: '#fb9403',
  dairyBlue: '#009fe1',
  red1:'#F03540'
};
