export const RULE_REQUEST = "RULE_REQUEST";
export const RULE_SUCCESS = "RULE_SUCCESS";
export const QUOTE_REQUEST = "QUOTE_REQUEST";
export const QUOTE_SUCCESS = "QUOTE_SUCCESS";
export const QUOTE_REQUEST_RETURN = "QUOTE_REQUEST_RETURN";
export const QUOTE_SUCCESS_RETURN = "QUOTE_SUCCESS_RETURN";
export const RULE_REQUEST_RETURN = "RULE_REQUEST_RETURN";
export const RULE_SUCCESS_RETURN = "RULE_SUCCESS_RETURN";
export const SET_LOADING = "SET_LOADING";
export const FALSE_ALL_DATA = "FALSE_ALL_DATA";
export const CLEAR_ALL_FILGHT_FARE_QUOUTE = "CLEAR_ALL_FILGHT_FARE_QUOUTE";
