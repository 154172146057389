import React from 'react'

function InnerNavbar1() {
  return (
    <div style={{width:"100%",height:"200px",backgroundColor:"pink"}}>
      rachna
    </div>
  )
}

export default InnerNavbar1
