import { fontSize } from "@mui/system";

export const InnerBarMoreLogo = [
  
  {
    avatar: require("./Images/taxi.png"),
    name: "Transfer",
    path: "/transfer",
  },
  {
    avatar: require("./Images/currency-exchange.png"),
    name: "Forex",
    path: "/forex",
  },
  {
    avatar: require("./Images/24-hours-support.png"),
    name: "Services",
    path: "/services",
  },
  {
    avatar: require("./Images/user.png"),
    name: "Account",
    path: "/account",
  },
  {
    avatar: require("./Images/businessman.png"),
    name: "Admin",
    path: "/admin",
  },
  {
    avatar: require("./Images/accounting.png"),
    name: "GST",
    path: "/gst",
  },
 
 
 
 
];
