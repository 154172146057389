import React from 'react'
import FlightresultReturn from './FlightresultReturn'
const FlightResResult = () => {
    return (
        <div className='container bg-light'>
            <div className="row">
                <FlightresultReturn />
            </div>
        </div>
    )
}

export default FlightResResult
