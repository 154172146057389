import MainPage from "./UI/MainPage";

function App() {
  return (
    <>
      <MainPage />
    </>
  );
}

export default App;
