import React from 'react';
import ForexForm from "./ForexForm/ForexForm";

const Forex = () => {
  return (
    <div className='container-fluid margin-pecentage'>
      <ForexForm />
    </div>
  )
}

export default Forex
