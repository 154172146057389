import React from 'react'

const Booknow = () => {
  return (
    <div>
      hello prit!
    </div>
  )
}

export default Booknow
