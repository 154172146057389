
export const BanerImage=[
    {
        avatar:require("./Images/newBanner1.jpg")
    },
    {
        avatar:require("./Images/newBanner3.jpg")
    },
    {
        avatar:require("./Images/newBanner2.jpg")
    },
    {
        avatar:require("./Images/newBanner3.jpg")
    }
    // {
    //     avatar:require("./Images/experience.png")
    // },
    // {
    //     avatar:require("./Images/ladakh.png")
    // },
    // {
    //     avatar:require("./Images/Singapur.png")
    // }, 
    // {
    //     avatar:require("./Images/Travvolt.png")
    // }
]