export const Holiday = [
  {
    tittle: "Search",
    path: "/holidaypackage",
  },
  {
    tittle: "Create Holiday Package",
    path: "/CreateHolidayPackage",
    name: "Create Holiday Package",
  },
];
