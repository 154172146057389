import React from 'react'
// import Holidaystepper from './holidaystepper/Holidaystepper'
import HolidayForm from './holidayform/HolidayForm'
const HotelPackage = () => {
  return (
    <div className="container-fluid " id='margin-pecentage-large'>
      <HolidayForm />
    </div>
  )
}

export default HotelPackage
